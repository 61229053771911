body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    max-width: 900px;
    margin: 2rem auto 0 auto;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    font-size: 1rem;
    box-shadow: 1px 1px 9px 7px #36181833;
    color: #282c34
    
}

.container {
  padding: 0 1rem;
  text-align: right;
  
}
input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
}
input[type=submit]:disabled {
  width: 100%;
  background-color: #807c7c;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
}
img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 550px;
}
img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
input[type=submit]:hover {
  background-color: #45a049;
}

input[type=submit]:disabled {
  background-color: #807c7c;
}

.add-post{
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 1rem;
  padding: 1rem;
}

.image{
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  max-width: 100%;
  height: auto;
}
.comment{
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
}

.posts{
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 9px 7px #36181833;
  /* text-align: center; */
  padding: .5rem;
}


.posts div{
  box-shadow: 1px 1px 1px rgb(182, 235, 171);
  padding: .5rem;
  margin: .6rem;
  background-color: white;
}

.modal{
   position: absolute;
   top:40px;
   right: 200px;
   width: 60%;
   height: 40%;
   box-shadow: 4px 4px 4px rgb(114, 116, 114);
   animation: move .2s backwards;
}
.modal-likes{
  position: absolute;
  top:40px;
  right: 200px;
  width: 60%;
  height: 40%;
  box-shadow: 4px 4px 4px rgb(114, 116, 114);
  animation: move .2s backwards;
}
div {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
 .clean-area  {
  border-radius: 0px;
  background-color: #45a049;
}
.btn-delete {
  display: inline-block;
  width: 100%;
  border-radius: 4px;
  /* border: none; */
  background: #0ca5e297;
  color: #ffffff;
  padding: 7px 20px;
  cursor: pointer;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.close{
  width:40px;
  border-radius: 150px;
  float: right;
}
input,textarea{
  margin: .3rem;
  padding: 1rem;
  font-size: 1.2rem;
}
button{
  width:30%;
  background-color: rgb(49, 142, 248);
  padding: .8rem;
  font-weight: 500;
  border-radius: 4px;
  float: right;
  color: aliceblue;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 15px;
}

.users-liked {
  width: 100px;
  color: #282c34;
  border: none;
  background-color: "gray";
  padding: 2px;
}
.alert {
   font-weight: 200;
  color: crimson;
  font-style: italic;
  font-size: 14px;
}
.like-button {
   font-weight: 600;
   border-radius: 6;
   padding: "8px";
   color: rgb(80, 82, 80);
   width: "40px";
   font-style: italic;
   cursor: pointer;
}


button:hover{
  background-color: rgba(9, 123, 216, 0.808);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
